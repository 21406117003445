import React, { useEffect, useState } from 'react';

import {
  Button,
  View,
} from '@/app';

import { Page } from '@/shared'

const notFound = () => {
  return (
    <>
      <Page>
        {/* <NotFound /> */}
      </Page>
    </>
  )
}

export default notFound;
